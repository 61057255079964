
import React, { useEffect } from 'react';
import styles from './design_1.module.css';

/*
const inventorySchema = new mongoose.Schema({
    interest_from: { type: String, required: false },
    name: { type: String, required: true },
    manufacturer_creator_url: { type: String, required: false },
    manufacturer_creator: { type: String, required: false },
    manufacturer_url_author_title_date: { type: String, required: false },
    reviews_url: { type: String, required: false },
    reviews_url_author_title_date: { type: String, required: false },
    instructions_url: { type: String, required: false },
    instructions_url_author_title_date: { type: String, required: false },
    description: { type: String, required: false },
    advantages: { type: String, required: false },
    disadvantages: { type: String, required: false },
    tags_category: { type: [String], required: false }, // Assuming tags can be multiple, hence an array
    resources: { type: String, required: false },
    vendors: { type: String, required: false },
    cost: { type: String, required: false },
    vetted_by_bvi_users: { type: String, required: false },
    published_reviews_secondary_sources: { type: String, required: false },
    patents: { type: String, required: false },
    dissertations: { type: String, required: false }
});
*/

function Page() {

  const search = async () => {
    const input = document.getElementById('search');
    const filter = input.value.toUpperCase();
    const inventoryContainer = document.getElementById('inventoryContainer');
    const items = inventoryContainer.querySelectorAll('#itemContainer');
    
    for (let item of items) {
      
      console.log(item.querySelector(`.${styles.name}`).textContent)
      console.log(item.querySelector(`.${styles.desc}`).textContent)
      const name_ = item.querySelector(`.${styles.name}`).textContent.toUpperCase();
      const desc = item.querySelector(`.${styles.desc}`).textContent.toUpperCase();
      

      if (name_.indexOf(filter) > -1 || desc.indexOf(filter) > -1) {
        item.style.display = '';
        console.log('Item displayed:', item);
      }
      else {
        item.style.display = 'none';
        console.log('Item hidden:', item);
      } 
    }
  }
useEffect(() => {
    const render_table = () => {
      // http://localhost:3500/get_data
      // https://visionassisthub.org/get_data
      fetch('https://visionassisthub.org/get_data')
      .then(response => response.json())
      .then(data => {
        //console.log('Data:', data);
        const inventoryContainer = document.getElementById('inventoryContainer');
        
        data.forEach(item => {
          const itemContainer = document.createElement('div');
          itemContainer.setAttribute('aria-label', `Item container for ${item.name}`);
          const itemHeader = document.createElement('div');
      
          itemContainer.className = styles.itemContainer;
          itemContainer.id = 'itemContainer';
          itemHeader.classList.add(styles.itemHeader);
  
          const name = document.createElement('h3');
          name.textContent = item.name;
          name.setAttribute('aria-hidden', 'true');
          name.classList.add(styles.name);  
          name.className = styles.name;
          
          itemHeader.appendChild(name);
  
          const clickMe = document.createElement('p');
          clickMe.setAttribute('aria-hidden', 'true');
          clickMe.className = styles.clickMe;
          clickMe.textContent = 'Double click anywhere to expand';
    
          itemHeader.appendChild(clickMe);
          itemContainer.appendChild(itemHeader);
  
          const category = document.createElement('p');
          category.className = styles.category;
          category.textContent = `Category: ${item.tags_category}`;
          itemContainer.appendChild(category);
  
          const cost = document.createElement('p');
          cost.className = styles.cost;
          cost.textContent = `Cost: ${item.cost}`;
          itemContainer.appendChild(cost);
  
          const description = document.createElement('p');
          description.textContent = `Description: ${item.description}`;
          description.classList.add(styles.desc);
          description.className = styles.desc;
          itemContainer.appendChild(description);
  
          const reviews_url = document.createElement('a');
          reviews_url.className = styles.reviews_url;
          reviews_url.textContent = `Reviews: ${item.reviews_url}`;
          reviews_url.href = item.reviews_url;
          itemContainer.appendChild(reviews_url);
  
          const instructions_url = document.createElement('a');
          instructions_url.className = styles.instructions_url;
          instructions_url.textContent = `Instructions: ${item.instructions_url}`;
          instructions_url.href = item.instructions_url;
          itemContainer.appendChild(instructions_url);
  
          const advantages = document.createElement('p');
          advantages.className = styles.advantages;
          advantages.textContent = `Advantages: ${item.advantages}`;
          itemContainer.appendChild(advantages);
  
          const disadvantages = document.createElement('p');
          disadvantages.className = styles.disadvantages;
          disadvantages.textContent = `Disadvantages: ${item.disadvantages}`;
          itemContainer.appendChild(disadvantages);
  
          const manufacturer_creator = document.createElement('p');
          manufacturer_creator.className = styles.manufacturer;
          manufacturer_creator.textContent = `Manufacturer/Creator: ${item.manufacturer_creator}`;
          itemContainer.appendChild(manufacturer_creator);
  
          const manufacturer_creator_url = document.createElement('a');
          manufacturer_creator_url.className = styles.manufacturer_url;
          manufacturer_creator_url.textContent = `Manufacturer URL: ${item.manufacturer_creator_url}`;
          manufacturer_creator_url.href = item.manufacturer_creator_url;
          itemContainer.appendChild(manufacturer_creator_url);
  
          const vendors = document.createElement('p');
          vendors.classList.add(styles.vendors);
          vendors.textContent = `Vendors: ${item.vendors}`;
          itemContainer.appendChild(vendors);
  
          const resources = document.createElement('p');
          resources.classList.add(styles.resources);
          resources.textContent = `Resources: ${item.resources}`;
          itemContainer.appendChild(resources);
  
          const vetted_by_bvi_users = document.createElement('p');
          vetted_by_bvi_users.classList.add(styles.vetted_by_bvi_users);
          vetted_by_bvi_users.textContent = `Vetted by BVI Users: ${item.vetted_by_bvi_users}`;
          itemContainer.appendChild(vetted_by_bvi_users);
  
          const dissertations = document.createElement('p');
          dissertations.classList.add(styles.dissertations);
          dissertations.textContent = `Dissertations: ${item.dissertations}`;
          itemContainer.appendChild(dissertations);
  
          // add an id to the itemContainer
          itemContainer.id = 'itemContainer';
          inventoryContainer.appendChild(itemContainer);
  
          itemHeader.setAttribute('tabindex', '0'); // Makes the div focusable
  
          itemHeader.addEventListener('keydown', function(e) {
            if (e.key === 'Enter') {
              // Code to expand the itemContainer
              itemContainer.classList.toggle(styles.expanded);
            }
          });
  
          itemContainer.addEventListener('dblclick', function() {
            this.classList.toggle(styles.expanded);
            // get the number of next siblings
            for (let i = 0; i < this.parentElement.children.length; i++) {
              // if the current item is the one clicked and it is an even number 
              if (this.parentElement.children[i] === this && i % 2 === 0 ) {
                this.parentElement.children[i + 1].classList.toggle(styles.expanded);
              }
              // if the current item is the one clicked and it is an odd number
              else if (this.parentElement.children[i] === this && i % 2 !== 0) {
                this.parentElement.children[i - 1].classList.toggle(styles.expanded);
              }
            }   
         });
        })
        // add event listener to search bar for onkeyup event
        document.getElementById('search').addEventListener('keyup', search);
      })
    }
    render_table();
  }, []); // Empty dependency array because render_table is now defined inside useEffect


  return (
      <React.Fragment>
            <header className={styles.header}>
               <h1 className={styles.h1} aria-label="Page name: Inventory Database">Inventory Database</h1>
               {/* search bar - trigger script upon receiving a return keystroke */}
                <input className={styles.search} id='search' type="text" placeholder="Search..." role="search" aria-label="Search visually assistive technology" />
                {/* add a link to home */}
                <nav className={styles.nav}>
                  {/*<button className={styles.nav_button} onClick={() => window.location.href = 'https://visionassisthub.org/design_2'}>View Design 2</button>*/}
                  <button className={styles.nav_button} onClick={() => window.location.href = 'https://visionassisthub.org/'}>Home</button>
                </nav>
            </header>
            <main className={styles.main}>
              <div id='inventoryContainer' className={styles.inventoryContainer}></div>
            </main>
            <footer className={styles.footer} role="contentinfo">
               ©CCAVA 2024
            </footer>
      </React.Fragment>
   );
}

export default Page;


